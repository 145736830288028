.empty-404 {
  width: 100%;
  text-align: center;
  padding-top: 30px;

  .waraemon {
    width: 180px;
  }//.waraemon
  .message {
    font-size: 14px;
    margin-top: 30px;
  }//.message
}//.empty-404
